<template>
  <div class="card">
    <div class="title">
      <div>角色</div>
      <!-- <div>顺序</div> -->
      <!-- <div>类型</div> -->
      <div>业绩金额</div>
      <div>提成金额</div>
      <!-- <div>操作</div> -->
    </div>
    <template v-if="orderList&&orderList.length">
      <template v-for="(item, index) in orderList" :key="index">
        <div class="project-item">
          <div class="content">
              <div class="goods-item">
                <div class="item">
                  <div class="key">订单号：</div>
                  <div class="value">{{ item.orderNo }}</div>
                </div>

                <div class="item">
                  <div class="key">商品:</div>
                  <div class="value">{{ item.productName }}</div>
                </div>
                <div class="item">
                  <div class="key">单价:</div>
                  <div class="value">¥ {{ item.price }}</div>
                </div>
                <div class="item">
                  <div class="key">数量:</div>
                  <div class="value">1</div>
                </div>
              </div>

            <div class="operate" v-for="(items,indexs) in item.userList" :key="indexs">
              <!-- @click="optTeacherClick(item, index)" -->
              <div>
                <div>{{ items.name }}</div>
              </div>
              <!-- <div>{{ index + 1 }}</div> -->
              <!-- <div>现金</div> -->
              <div>
                <el-input
                  oninput ="value=value.replace(/[^0-9.]/g,'')"
                  v-model="items.performance"
                  placeholder=""
                >
                  <template #suffix> |元 </template>
                </el-input>
              </div>
              <div>
                <el-input
                  oninput ="value=value.replace(/[^0-9.]/g,'')"
                  v-model="items.commission"
                  placeholder=""
                >
                  <template #suffix> |元 </template>
                </el-input>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <el-empty description="暂无相关数据" />
    </template>

    <div class="save-btn">
      <el-button @click="handleSaveClick" type="primary">保存</el-button>
      <el-button @click="handleCancleClick">取消</el-button>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { getTeacherScore, getOrderYejiList, updateOrderScore,getOrderPerformanceCommission,setOrderPerformanceCommission } from '@/service/main/order'
import { useRoute, useRouter } from 'vue-router'
import commissionSettingsModule from '@/store/model/commission-settings'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    projectList: {
      type: Array,
      default() {
        return []
      }
    },
    // 默认是服务订单
    orderKind: {
      type: Number,
      default: 1
    }
  },
  components: {

  },

  emits: ['optTeacherClick'],
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()

    const optTeacherClick = (item, index) => {
      let obj = { ...item, index }
      emit('optTeacherClick', obj)
    }

    const orderList = ref([])

    const handleCancleClick = () => {
      router.go(-1)
    }

    const handleSaveClick = async() => {
      let array = []
      orderList.value.map(item=>{
        item.userList.map(items=>{
          array.push({...items,orderItemId:item.itemId})
        })
      })
      let flag = array.every(item=>{ return item.performance&&item.commission })
      if (!flag) {
        ElMessage({
          message: '参数填写不完整!',
          type: 'warning',
        })
        return
      }
      let params = {
        orderId:route.query.orderId,
        itemList:array
      }
      let data = await setOrderPerformanceCommission(params)
      if (data.code==0) {
        ElMessage({
          message: '操作成功',
          type: 'success',
        })
      }else{
        ElMessage({
          message: data.msg,
          type: 'error',
        })
      }
    }
    const initPage = async () => {
      const orderId = route.query.orderId
      const res = await getOrderPerformanceCommission(orderId)
      res.data.map(item=>{
        item.userList.map(items=>{
          items.performance=items.performance.toString()
          items.commission=items.commission.toString()
        })
      })
      orderList.value = res.data
    }
    initPage()


    return {
      handleCancleClick,
      handleSaveClick,
      optTeacherClick,
      UPLOAD_BASE_URL,
      orderList,
    }

  }
})
</script>

<style scoped lang="less">
.card {
  font-size: 14px;

  .save-btn {
    display: flex;
    justify-content: center;

    &:deep(.el-button) {
      margin: 80px 0;
      margin-left: 40px;
    }
  }

  .title {
    display: flex;
    background-color: rgb(247, 248, 250);
    line-height: 56px;
    padding: 0 10px;

    & > div {
      flex: 1;
    }
  }

  .project-item {
    .operate {
      display: flex;

      & > div:nth-child(1) {
        color: rgb(133, 88, 250);
        cursor: pointer;
        border-left: 1px solid rgb(235, 237, 240);
      }

      & > div {
        padding: 0 10px;
        flex: 1;
        line-height: 70px;
        border: 1px solid rgb(235, 237, 240);
        border-left: none;
      }

      &:deep(.el-input) {
        max-width: 100px;
      }
    }

    .content {
      .goods-item {
        padding: 0 10px;
        display: flex;

        height: 70px;
        margin-top: 24px;
        background-color: rgb(247, 248, 250);

        .start-item {
          min-width: 220px !important;
        }

        .sjsk {
          .key,
          .value {
            color: rgb(50, 50, 50) !important;
          }
        }

        .item {
          display: flex;
          line-height: 70px;
          margin-right: 20px;

          .key,
          .value {
            color: rgb(150, 151, 153);
          }

          .goods-name {
            line-height: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .huiyuanka {
              font-size: 12px;
              border: 1px solid rgb(100, 101, 102);
              line-height: 18px;
              width: 46px;
              text-align: center;
              color: rgb(rgb(100, 101, 102));
            }
          }

          .goods {
            display: flex;
            align-items: center;

            .goods-img {
              display: flex;
              align-items: center;
              margin: 0 16px;

              img {
                width: 38px;
              }
            }
          }
        }
      }
    }
  }

  &:deep(.icon) {
    margin: 0 10px;
  }

  .btns {
    display: flex;
    justify-content: center;
    padding: 30px;

    &:deep(.el-button) {
      margin: 0 10px;
    }
  }
}
</style>
