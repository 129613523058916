<template>
  <div class="update-staff-score">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="main-content">
      <card
        :orderKind="orderDetail.orderKind"
        :projectList="orderDetail.projectList"
        @optTeacherClick="optTeacherClick"
      ></card>
      <teacherDialog
        @optStaffChange="optStaffChange"
        @changeDialogVisible="changeDialogVisible"
        :dialogVisible="dialogVisible"
      ></teacherDialog>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import card from './cpns/card/card.vue'
import serve from './cpns/serve/serve.vue'
import teacherDialog from './cpns/teacher-dialog/teacher-dialog.vue'
import { useRoute } from 'vue-router'
import { getTichengList } from '@/service/main/order'
import { COMPANY_ID } from '@/common/constant'

export default defineComponent({
  props: {

  },
  components: {
    breadcrumb,
    card,
    serve,
    teacherDialog
  },
  setup() {
    const route = useRoute()

    const dialogVisible = ref(false)
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
    }


    const optTeacherClick = (item) => {
      // console.log(item);
      tichengForm.curretIndex = item.index
      dialogVisible.value = true
    }

    const tichengForm = reactive({
      info: {},
      curretIndex: -1,//当前操作的商品索引
    })

    const orderDetail = reactive({
      orderKind: 1,
      projectList: []
    })

    const optStaffChange = item => {
      let index = tichengForm.curretIndex
      orderDetail.projectList[index]['updateEmpName'] = item.userRealname
      orderDetail.projectList[index]['updateEmpId'] = item.userId
      // console.log(item);
    }


    const initPage = async () => {
      orderDetail.orderKind = route.query.type
    }
    initPage()

    return {
      optStaffChange,
      orderDetail,
      tichengForm,
      optTeacherClick,
      changeDialogVisible,
      breadcrumbList,
      dialogVisible,
      COMPANY_ID
    }

  }
})
</script>

<style scoped lang="less">
.update-staff-score {
  min-width: 1080px;

  .main-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>
