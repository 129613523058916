<template>
  <div class="teacher-dialog">
    <page-dialog @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="添加员工"
      :isShowFooter="true">
      <el-scrollbar height="400px">
        <div class="staff-lsit">
          <template v-for="(item, index) in teacherList">
            <div @click="handleItemClick(item, index)" :class="{ active: currentIndex == index }" class="item">{{
               item.userRealname  }}</div>
          </template>
        </div>
      </el-scrollbar>
      <template #footer>
        <div class="save-btn">
          <el-button @click="handleSaveClick" size="small" type="primary">保存</el-button>
        </div>
      </template>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import pageDialog from '@/components/page-dialog/page-dialog.vue'
import { getPaibanShopList } from '@/service/main/staff'
import cache from '@/utils/cache'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
  },
  components: {
    pageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const teacherList = ref([])
    const radio = ref('-1')
    const currentIndex = ref(-1)
    const currentStaff=ref()


    const handleItemClick = (item, index) => {
      currentIndex.value = index
      radio.value = item.userId
      currentStaff.value=item
    }

    const handleSaveClick = () => {
      if (radio.value == -1) {
        ElMessage({
          message: '请选择员工！',
          type: 'warning',
        })
        return
      }
      emit('optStaffChange',currentStaff.value)
      changeDialogVisible(false)
    }

    const initPage = async () => {
      const adminInfo = cache.getCache('adminInfo')
      let { shopId } = adminInfo
      const res = await getPaibanShopList(shopId)
      let list=res.data.list
      list=list.filter(ele=>ele.status==1)
      teacherList.value = list
    }
    initPage()

    return {
      currentStaff,
      handleSaveClick,
      handleItemClick,
      changeDialogVisible,
      radio,
      teacherList,
      currentIndex
    }

  }
})
</script>

<style scoped lang="less">
.teacher-dialog {
  .staff-lsit {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .active {
      background-color: rgb(237, 234, 255);
      border-color: rgb(133, 88, 250) !important;
    }

    .item:hover {
      background-color: rgb(237, 234, 255);
    }

    .item {
      cursor: pointer;
      padding: 0 10px;
      width: 48%;
      line-height: 56px;
      margin-bottom: 20px;
      border: 1px solid rgb(235, 237, 240);
    }
  }
}
</style>




